import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import HeroBannerImage from 'components/HeroBannerImage';
import Services from 'components/Services';
import Testimonials from 'components/Testimonials';
import { PageProps } from 'gatsby';
import LatestPosts from 'components/Posts/LatestPosts';



const IndexPage: React.FC<PageProps> = (   pageProps ) => {
  return (
    <Layout location={pageProps.location}>
      <SEO title="Blog About Dynamics 365 and the Power Platform" />
      <HeroBannerImage    
        title="Blog About Dynamics 365 and the Power Platform"
        height="440px"
        mobileHeight="260px"
         >
           <div className="flex w-full h-full ">
           <div class="m-auto text-white font-semibold text-center text-shadow">
             <h1 className="-m-4 text-5xl">Joris Kalz Blog</h1>
             <h2 className="text-2xl">Thoughts, projects and ideas</h2>
           </div>
           </div>
         </HeroBannerImage>
      <LatestPosts />
      {/* <Services /> */}
      <hr />
      {/* <Testimonials /> */}
    </Layout>
  );
};

export default IndexPage;
