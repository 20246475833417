import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import styled from "styled-components";
import { useStaticQuery, graphql } from 'gatsby';


const Parent = styled.div`
  position: relative;
  background-color: ${({ bc }) => bc};
`;
const FakeBgImage = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ height }) => height};
  z-index: -1;
  & > img {
    object-fit: cover !important;
    object-position: 0% 0% !important;
    font-family: "object-fit: cover !important; object-position: 0% 0% !important;";
  }
  @media screen and (max-width: 600px) {
    height: ${({ mobileHeight }) => mobileHeight};
  }
`;
const Content = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`;
const HeroBannerImage = ({
  fluid,
  title,
  height,
  mobileHeight,
  overlayColor,
  children,
  className,
}) => {

    const { site, placeholderImage } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      placeholderImage: file(relativePath: { eq: "blogbg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 45) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const logoTitle = site.siteMetadata.title;
  const logoImage = placeholderImage.childImageSharp.fluid;


  return (
  <Parent bc={overlayColor}>
    <FakeBgImage
      fluid={logoImage}
      title={title}
      height={height}
      mobileHeight={mobileHeight}
    />
    <Content className={className}>{children}</Content>
  </Parent>
)};
HeroBannerImage.propTypes = {
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
  mobileHeight: PropTypes.string,
  overlayColor: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};
HeroBannerImage.defaultProps = {
  height: null,
  mobileHeight: null,
  overlayColor: "transparent",
  children: null,
  className: null,
};
export default HeroBannerImage;





// import React from 'react';
// import { useStaticQuery, graphql } from 'gatsby';
// import Img from 'gatsby-image';

// import * as Styled from './styles';
// import { ImageSharpFluid } from 'helpers/definitions';

// import { SectionTitle } from 'helpers/definitions';

// interface SectionHeroBanner extends SectionTitle {
//   content: string;
//   linkTo: string;
//   linkText: string;
// }

// const HeroBannerImage: React.FC = () => {
//   const { site, placeholderImage } = useStaticQuery(graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     placeholderImage: file(relativePath: { eq: "blogbg.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 1920) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `);

// const logoTitle: string = site.siteMetadata.title;
// const logoImage: ImageSharpFluid = placeholderImage.childImageSharp.fluid;

//   return (
//     <Styled.Hero style={{
//       backgroundImage: logoImage
//     }}>
//       <Img fluid={logoImage} alt={logoTitle} />
//     </Styled.Hero>
//   );
// };

// export default HeroBannerImage;
